import React, { Component } from 'react'
import { graphql } from "gatsby";
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import Productions from '../components/OurProductions';

class ProductionsPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currentType: 'all'
		}
		this.handelTypeChanged = this.handelTypeChanged.bind(this)
	}
	handelTypeChanged(newType) {
		this.setState({
			currentType: newType
		})
	}
	render() {
		const { currentType } = this.state
		const { data: { allMarkdownRemark: { edges } } } = this.props
		const allProductions = edges.filter(item => {
			return item.node.fields.slug.includes('productions')
		})
		var productions = []
		if(currentType !== 'all'){
			productions = allProductions.filter(item => {
				return item.node.frontmatter.categorie === currentType
			})
		}else{
			productions = allProductions
		}
		return (
				<Layout>
					<Helmet
						title='Nos productions'
						meta={[
							{
								name: "description",
								content: 'Retouvez toutes nos productions'
							}
						]}
					/>
					<Productions data={productions} handleTypeChanged={this.handelTypeChanged}/>
        	</Layout>
			)
	}
}
export default ProductionsPage

export const query = graphql`
	query allProductionsQuery {
		allMarkdownRemark{
			edges {
				node {
					id
					fields{
						slug
					}
					frontmatter{
						title
						image
						categorie
						description
					}
				}
			}
		}
		}
`
import React from 'react'
import styles from '../productions.module.css'
import DevisForm from './DevisForm';

const DevisSection = () => {
    return (
        <div className={`ui stackable two column grid ${styles.devis_section}`}>
            <div className="column">
                <img src="/./img/devis.png" alt="Devis"/>
            </div>
            <div className="column">
                <DevisForm />
            </div>
        </div>
    )
}
export default DevisSection
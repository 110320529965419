import React from 'react'
import { Link } from 'gatsby';
import { Card } from 'semantic-ui-react';
import styles from '../productions.module.css'
import { cutString } from '../../Comon/utils';
import LazyLoad from 'react-lazyload'
import LazyPlaceHolder from '../../Comon/LazyPlaceHolder'

const ProductionsCards = ({ data, filter, showAll }) => {
    var productions = []
    const countToDisplay = (showAll) ? data.length : 6
    const allProduction = data.slice(0, countToDisplay)
    if (filter !== 'all') {
        productions = allProduction.filter(item => {
            return item.node.frontmatter.categorie === filter
        })
    } else {
        productions = allProduction
    }
    return (
        <div className="ui three column stackable grid">
            {productions.map((item, index) => {
                return (
                    <div className={`column ${styles.production_card}`} key={index}>
                        <Link to={item.node.fields.slug}>
                            <Card fluid>
                                <LazyLoad
                                    height={300}
                                    placeholder={<LazyPlaceHolder height={300} />}>
                                    <img src={item.node.frontmatter.image} alt={item.node.frontmatter.title} />
                                </LazyLoad>
                                <div className={styles.card_content}>
                                    <h3> {item.node.frontmatter.title} </h3>
                                    <p> {cutString(item.node.frontmatter.description, 100)} </p>
                                </div>
                            </Card>
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}
export default ProductionsCards
import React from "react";
import { navigate } from "gatsby-link";
import { Button } from "semantic-ui-react";

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default class DevisForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isValidated: false, loading: false };
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true });
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
            .then(() => navigate(form.getAttribute("action")))
            .catch(error => alert(error));
    };

    render() {
        const { loading } = this.state;
        return (
            <section className="section">
                <div className="content">
                    <h1>
                        Demandez un devis
                    </h1>

                    <form
                        name="Demane de devis"
                        method="post"
                        action="/thanks"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={this.handleSubmit}
                        className="ui form"
                    >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="contact" />
                        <div hidden>
                            <label>
                                Don’t fill this out:{" "}
                                <input name="bot-field" onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="field">
                            <label htmlFor="nom">Votre nom:</label>
                            <input
                                id="nom"
                                className="input"
                                type={"text"}
                                name={"nom"}
                                onChange={this.handleChange}
                                placeholder="Votre nom"
                                required={true}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="mail">Votre adresse mail:</label>
                            <input
                                id="mail"
                                className="input"
                                type="email"
                                name="email"
                                onChange={this.handleChange}
                                placeholder="Votre adresse mail"
                                required={true}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="budget">Votre Budget:</label>
                            <input
                                id="budget"
                                className="input"
                                type="text"
                                name="text"
                                onChange={this.handleChange}
                                placeholder="Votre budget"
                                required={true}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="message">Decrivez votre projet</label>
                            <textarea
                                id="message"
                                className="textarea"
                                name="message"
                                onChange={this.handleChange}
                                required={true}
                            />
                        </div>
                        <div className="field">
                            <Button
                                disabled={loading}
                                loading={loading}
                                className="button is-link"
                                type="submit"
                                color="red"
                            >
                                Envoyer
                            </Button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

import React, { Component } from 'react'
import styles from './productions.module.css'
import { Tab, Button } from 'semantic-ui-react';
import ProductionsCards from './Helpers/ProductionsCards';
import DevisSection from './Helpers/DevisSection';

class Productions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAll: false
        }
        this.handelShowAll = this.handelShowAll.bind(this)
    }
    handelShowAll() {
        const { showAll } = this.state
        this.setState({
            showAll: !showAll
        })
    }
    render() {
        const { data } = this.props
        const { showAll } = this.state
        const panes = [
            { menuItem: 'Tous', render: () => <Tab.Pane> <ProductionsCards showAll={showAll} data={data} filter="all" /> </Tab.Pane> },
            { menuItem: 'Films', render: () => <Tab.Pane> <ProductionsCards showAll={showAll} data={data} filter="Film" /> </Tab.Pane> },
            { menuItem: 'Séries', render: () => <Tab.Pane> <ProductionsCards showAll={showAll} data={data} filter="Série TV" /> </Tab.Pane> },
            { menuItem: 'TV shows', render: () => <Tab.Pane> <ProductionsCards showAll={showAll} data={data} filter="TV Shows" /> </Tab.Pane> },
        ]
        return (
            <div>
                <section className={`header-parallax ${styles.hero}`}>
                    <div className="container">
                        <h1>Nos Productions</h1>
                    </div>
                </section>
                <section className={`container ${styles.productions_section}`}>
                    <Tab panes={panes} />
                    <Button color="red" onClick={this.handelShowAll} className={styles.see_more_btn}>Voir {(showAll)?"moins":"plus"}</Button>
                </section>
                <section className={`container ${styles.talking_section}`}>
                    <h1>Discutons un peut</h1>
                    <p>
                        Le Lorem Ipsum est simplement du faux
                        texte employé dans la composition et
                        la mise en page avant impression. Le
                        Lorem Ipsum est le faux texte standard de
                        l'imprimerie depuis les années 1500, quand
                        un imprimeur
                     </p>
                    <DevisSection />
                </section>
            </div>
        )
    }
}
export default Productions